'use client';

/* eslint-disable @typescript-eslint/naming-convention */

import { useRef, FormEvent } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import { localeAtom } from '@core/Atoms/Locale/Locale.atom';
import { tenantAtom } from '@core/Atoms/Tenant/Tenant.atom';
import { fetchJson } from '@core/Utils/Fetch/FetchJson';

import { authTokenAtom } from '../../../Atoms/AuthToken.atom';

interface UseAuthSubmitProps {
  url: string;
  method?: 'GET' | 'POST' | 'PATCH';
  authToken?: string;
  sendAsFormData?: boolean;
  onSuccess?: (response: any) => void;
  onError?: (error: any) => void;
  onSubmit?: (e: FormEvent<HTMLFormElement>) => false | void;
}

export const useAuthSubmit = ({
  url,
  method = 'POST',
  authToken,
  sendAsFormData,
  onSuccess,
  onError,
  onSubmit,
}: UseAuthSubmitProps) => {
  const formRef = useRef<HTMLFormElement>(null);
  const setAuthToken = useSetAtom(authTokenAtom);
  const locale = useAtomValue(localeAtom);
  const tenant = useAtomValue(tenantAtom);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    if (onSubmit && onSubmit(e) === false) {
      e.preventDefault();
      return;
    }

    if (!formRef.current) return;
    if (!formRef.current.checkValidity()) return;

    e.preventDefault();

    const formData = new FormData(formRef.current);
    formData.append('tenant', locale);
    formData.append('site', tenant ?? 'heycar');

    const data: Record<string, any> = Object.fromEntries(formData.entries());

    if (data.postcode) {
      data.address = { postcode: data.postcode };
      delete data.postcode;
    }

    const formDataBody = {};
    formData.forEach((value, key) => ((formDataBody as any)[key] = value));
    const urlEncodedData = new URLSearchParams(Object.entries(formDataBody)).toString();

    const payload = sendAsFormData ? urlEncodedData : JSON.stringify(data);

    try {
      const response = await fetchJson<{ access_token: string }>(url, {
        method,
        body: payload,
        headers: {
          Authorization: authToken ? `Bearer ${authToken}` : undefined,
          'x-forwarded-host': window.location.host,
          'x-forwarded-proto': window.location.protocol.replace(':', ''),
          'Content-Type': sendAsFormData ? 'application/x-www-form-urlencoded' : 'application/json',
          Accept: undefined,
        },
      });

      if (!authToken && response?.access_token) setAuthToken(response.access_token);

      onSuccess?.(response);
    } catch (error) {
      onError?.(error);
    }
  };

  return { formRef, handleSubmit };
};
