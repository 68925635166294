import { useState } from 'react';

import { Eye } from '@gds/Icons/Paths/Eye';
import { Input } from '@gds/Input/Input';

import styles from './PasswordInput.module.css';

type Props = {
  name: string;
  placeholder: string;
  error?: string;
  id?: string;
  dataTestId: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

export const PasswordInput = (props: Props) => {
  const [shouldShowPassword, setShouldShowPassword] = useState(false);

  return (
    <div className={styles.passwordInputContainer}>
      <div
        className={styles.showPasswordIcon}
        data-is-active={shouldShowPassword}
        onClick={() => setShouldShowPassword(!shouldShowPassword)}
      >
        <Eye />
      </div>
      <Input type={shouldShowPassword ? 'text' : 'password'} fullWidth {...props} />
    </div>
  );
};
