'use client';

import { Ref, useState } from 'react';

import { useAtom, useAtomValue } from 'jotai';

import { localeAtom } from '@core/Atoms/Locale/Locale.atom';

import { AccountFormDefaultMessages } from '../AccLoginForm/AccountForm.messages';

import { AccountFieldMap } from './AccountFieldMap';
import { accountFormDataAtom } from './AccountFormData.atom';
import { AccountFormData, FormConfig } from './FormConfig.entity';

type Props = {
  strings: AccountFormDefaultMessages;
  config: FormConfig;
  formEl: Ref<HTMLFormElement>;
};

type FormErrors = Partial<Record<keyof AccountFormData, string>>;

export const AccountFormFields = ({ strings, config, formEl }: Props) => {
  const locale = useAtomValue(localeAtom);
  const [formData, setFormData] = useAtom(accountFormDataAtom);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [touchedFields, setTouchedFields] = useState<{ [key: string]: boolean }>({});

  const handleChange = async (
    { field, value }: { field: FormConfig['fields'][number]; value: any },
    shouldForce = false,
  ) => {
    let adjustedValue: any;

    if (field?.transformValue) {
      adjustedValue = field?.transformValue({ value, formData });

      const fieldEl = formEl?.current?.querySelector?.(`[name="${field.name}"]`);
      fieldEl.value = adjustedValue;
    }

    adjustedValue ??= value;

    const updatedFormData = { ...formData, [field.id]: adjustedValue };
    setFormData(updatedFormData);

    for (const currentField of config.fields) {
      const fieldValue = updatedFormData[currentField.id];
      const error = await currentField?.validate?.({
        formData: updatedFormData,
        value: fieldValue,
        strings,
        locale,
      });

      const fieldEl = formEl?.current?.querySelector?.(`[name="${currentField.name}"]`);

      if (!touchedFields[currentField.id] && !(shouldForce && currentField.id === field.id)) {
        continue;
      }

      fieldEl?.setCustomValidity?.(error ?? '');

      setFormErrors(prevErrors => ({ ...prevErrors, [currentField.id]: error }));
    }
  };

  const handleBlur = ({ field, value }: { field: any; value: any }) => {
    setTouchedFields(prevTouched => ({ ...prevTouched, [field.id]: true }));
    handleChange({ field, value }, true);
  };

  return (
    <>
      {config.fields.map(field => {
        const Field = AccountFieldMap[field.type];
        if (!Field) return null;

        return (
          <Field
            field={field}
            key={field.id}
            error={formErrors[field.id]}
            onBlur={e => handleBlur({ field, value: e.target.value })}
            onChange={e => handleChange({ field, value: e.target.value })}
            strings={strings}
            dataTestId={`${config.id}-${field.id}`}
          />
        );
      })}
    </>
  );
};
