import { Input } from '@gds/Input/Input';

import { AccountFormDefaultMessages } from '../AccLoginForm/AccountForm.messages';
import { PasswordInput } from '../AccLoginForm/Elements/PasswordInput';

import { FormConfig } from './FormConfig.entity';

export const AccountFieldMap: {
  [key: string]: React.FC<{
    field: FormConfig['fields'][number];
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
    error?: string;
    dataTestId: string;
    strings: AccountFormDefaultMessages;
  }>;
} = {
  text: ({ field, onChange, onBlur, error, strings, dataTestId }) => {
    return (
      <Input
        name={field.name}
        type="text"
        id={field.id}
        placeholder={field.placeholder?.({ strings })}
        required={field.required}
        fullWidth
        onBlur={onBlur}
        onChange={onChange}
        error={error}
        data-error={error}
        dataTestId={dataTestId}
      />
    );
  },
  password: ({ field, onChange, onBlur, error, strings, dataTestId }) => {
    return (
      <PasswordInput
        name={field.name}
        id={field.id}
        placeholder={field.placeholder?.({ strings })}
        onBlur={onBlur}
        onChange={onChange}
        error={error}
        data-error={error}
        dataTestId={dataTestId}
      />
    );
  },
};
